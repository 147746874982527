// JavaScript Document

// vendor

import jQuery from "jquery";
import "flexslider";

// custom

jQuery(document).ready(function () {
    
    if (document.documentMode === 10) {
        document.documentElement.className += " ie10";
    }
    else if (document.documentMode === 11) {
        document.documentElement.className += " ie11";
    }    
    
    jQuery(".menu-toggle").click(function () {
        jQuery("body").toggleClass("menu-open");
    });
    jQuery(".overlay").on("click touchstart",function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".header-menu ul li").removeClass("open");
    });
    jQuery(".close-icon").on("click touchstart",function () {
        jQuery("body").removeClass("menu-open");
        jQuery(".header-menu ul li").removeClass("open");
    });

    jQuery(".main-navigation .sub-menu").parent().prepend("<span class=\"menu-arrow\"><i class=\"fas fa-caret-down\"></i></span>");
    jQuery(".main-navigation .menu li > .menu-arrow").on("click touchstart", function (event) {
        jQuery(this).toggleClass("active").parent().siblings().children(".menu-arrow").removeClass("active");
        event.preventDefault();
        event.stopPropagation();
        jQuery(this).parent().toggleClass("open").siblings().removeClass("open");
    });

    // Can also be used with $(document).ready()
    jQuery(".flexslider").flexslider({
        animation: "slide",
        controlNav: "thumbnails",
        nextText: "",
        prevText: ""
    });

});

/* ===== Equal Height row =======*/
const equalheight = function (container) {
    if (jQuery(window).width() > 767) {
        let currentDiv = 0;
        let currentTallest = 0;
        let currentRowStart = 0;
        const rowDivs = new Array();
        let jQueryel;
        jQuery(container).each(function () {
            jQueryel = jQuery(this);
            jQuery(jQueryel).innerHeight("auto");
            const topPosition = jQueryel.offset().top;
            if (currentRowStart != topPosition) {
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                    rowDivs[currentDiv].innerHeight(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPosition;
                currentTallest = jQueryel.innerHeight();
                rowDivs.push(jQueryel);
            } else {
                rowDivs.push(jQueryel);
                currentTallest = (currentTallest < jQueryel.innerHeight()) ? (jQueryel.innerHeight()) : (currentTallest);
            }
            for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
                rowDivs[currentDiv].innerHeight(currentTallest);
            }
        });
    } else {
        jQuery(container).height("auto");
    }
};

/* ===== global Height =======*/
function heightGlobal() {
    setTimeout(function () {
        equalheight (".list-box");
        equalheight (".floor-plan-box");
    }, 200);
}

/* =================== window Load ===================================*/
jQuery(window).on("load",function () {
    setTimeout(function () {
        heightGlobal();
    },1500);
});

/* ========================== window Resize ============================*/
jQuery(window).resize(function () {
    heightGlobal();
});
